.grid {
  grid-template-areas:
    'contact about other'
    'awardsBanner awardsBanner awardsBanner';
}

.contact {
  grid-area: contact;
}

.about {
  grid-area: about;
}

.other {
  grid-area: other;
}

.awardsBanner {
  grid-area: awardsBanner;
}
