.activeContainer {
  &:active {
    &:before {
      border: 2px solid #21bcf4; // stylelint-disable-line color-no-hex
      content: '';
      position: absolute;
      width: 52px;
      height: 52px;
      border-radius: 50%;
      display: block;
    }
  }
}
